import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage, IntlContextConsumer } from 'gatsby-plugin-intl';
// TODO: Customize scss for Jobs template to avoid unecessary styles
import '../pages/index.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default function Template({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) {
  return (
    <IntlContextConsumer>
      {({ language }) => (
        <Layout>
          <SEO title={frontmatter.title} />
          <div className="intro page-header">
            <div className="wrapper">
              <h3>
                <FormattedMessage id="work_with_us.title" />{' '}
              </h3>
              <h1>{frontmatter.title}</h1>
              {language !== 'pt' && (
                <div data-alert className="alert-box secondary">
                  <FormattedMessage id="work_with_us.portuguese_only" />
                </div>
              )}
            </div>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="row main-well" dangerouslySetInnerHTML={{ __html: html }} />
        </Layout>
      )}
    </IntlContextConsumer>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      html: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
